import { useContext } from "react";
import { learnContext } from "../providers/learnProvider";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { BackButton } from "@/components/PageContainer/BackButton";
import { useUniqueCategories } from "./hooks";
import { Ico } from "@/assets/icons";

const SubCategoryFilter = () => {
  const { selectedBrand, setSelectedSubCategory, selectedSubCategory } =
    useContext(learnContext);

  const uniqueCategories = useUniqueCategories() || [];

  const handleSelectSubCategories = (category: string) => {
    if (category === selectedSubCategory)
      return setSelectedSubCategory(undefined);

    setSelectedSubCategory(category);
  };

  return (
    <Box w={"100%"}>
      <BackButton to={-1} text="Back" />

      <Heading as={"h4"} mt={"16px"}>
        {`${selectedBrand?.name}: ${selectedBrand?.description}`}
      </Heading>

      {uniqueCategories.length > 1 && (
        <Box>
          <Text variant={"caption"} mt={4} mb={3}>
            Filtra por modelo de negocio:
          </Text>
          <Flex
            gap={3}
            justifyContent="space-between"
            flexDir={{ base: "column", lg: "row" }}
          >
            {uniqueCategories.map((category) => (
              <Flex
                key={category}
                border="1px solid"
                borderColor="neutral.100"
                py={3}
                px={6}
                borderRadius="8px"
                flex="1"
                textAlign="initial"
                onClick={() => handleSelectSubCategories(category ?? "")}
                bg={
                  category === selectedSubCategory ? "primary.400" : undefined
                }
                justifyContent={"space-between"}
                alignItems={"center"}
                cursor={"pointer"}
                _hover={{
                  bg:
                    category === selectedSubCategory
                      ? "primary.300"
                      : "neutral.100",
                }}
              >
                <Text
                  variant={"bold"}
                  color={category === selectedSubCategory ? "white" : undefined}
                >
                  {category}
                </Text>
                {category === selectedSubCategory && (
                  <Ico.Check fontSize={"20px"} color="white" />
                )}
              </Flex>
            ))}
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default SubCategoryFilter;

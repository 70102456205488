/* eslint-disable react-refresh/only-export-components */
import { GetUserDocument } from "@/graphql/getUser.generated";
import { useQuery } from "@apollo/client";
import { createContext, useEffect, useState } from "react";
import { CommitmentContextType } from "./types";
import { RemainingAgreement } from "@/schemaTypes";
import { GetRemainingAgreementDocument } from "./graphql/getRemainingComiitment.generated";
import { noCacheHeaders } from "@/utils/headers";

const commitmentContext = createContext<CommitmentContextType>({
  shouldAcceptAgreement: false,
  commitmentLoading: true,
});

const CommitmentProvider = ({ children }: { children: React.ReactNode }) => {
  const [remainingAgreementDuration, setRemainingAgreementDuration] = useState<
    RemainingAgreement | undefined
  >(undefined);

  const [shouldAcceptAgreement, setShouldAcceptAgreement] = useState(false);
  const [agreementDuration, setAgreementDuration] = useState<
    number | undefined
  >(undefined);

  const { data, loading } = useQuery(GetUserDocument, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.me) {
      setShouldAcceptAgreement(data.me.shouldAcceptAgreement ?? false);
      setAgreementDuration(data.me.agreementDuration ?? undefined);
    }
  }, [data]);

  const { data: remainingAgreementDurationResult } = useQuery(
    GetRemainingAgreementDocument,
    {
      fetchPolicy: "network-only",
      context: { headers: noCacheHeaders },
    }
  );

  useEffect(() => {
    const isAgreementActive =
      remainingAgreementDurationResult?.getRemainingAgreement &&
      (remainingAgreementDurationResult?.getRemainingAgreement.days > 0 ||
        remainingAgreementDurationResult?.getRemainingAgreement.months > 0);

    if (isAgreementActive) {
      setRemainingAgreementDuration(
        remainingAgreementDurationResult?.getRemainingAgreement as RemainingAgreement
      );
    }
  }, [remainingAgreementDurationResult]);

  return (
    <commitmentContext.Provider
      value={{
        shouldAcceptAgreement,
        agreementDuration,
        commitmentLoading: loading,
        remainingAgreementDuration,
      }}
    >
      {children}
    </commitmentContext.Provider>
  );
};

export { commitmentContext, CommitmentProvider };

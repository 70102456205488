import { useUser } from "@/providers/useUser";
import {
  CreateMasterClassInput,
  LiveSession,
  LiveSessionType,
} from "@/schemaTypes";
import moment from "moment";
import { useState } from "react";

export const useMasterClassState = (
  editMode?: boolean,
  initialMasterClass?: LiveSession
) => {
  const { user } = useUser();

  const getInitialState = (): CreateMasterClassInput => ({
    endDate: editMode
      ? initialMasterClass?.endDate ?? moment().add(1, "hour").toDate()
      : moment().add(1, "hour").toDate(),
    name: editMode ? initialMasterClass?.name || "" : "",
    startDate: editMode
      ? initialMasterClass?.startDate ?? moment().toDate()
      : moment().toDate(),
    userId: String(user?.id),
    liveSessionType: LiveSessionType.Masterclass,
    description: editMode ? initialMasterClass?.description || "" : "",
    imageUrlMentor: editMode ? initialMasterClass?.imageUrlMentor || "" : "",
    mentorId: editMode ? initialMasterClass?.mentorId || "" : "",
    mentorName: editMode ? initialMasterClass?.mentorName || "" : "",
    brandId: editMode
      ? initialMasterClass?.brand?.map((b) => b?.id ?? "") || []
      : [],
    subCategoryId: editMode
      ? [initialMasterClass?.subCategory?.[0]?.id || ""]
      : [""],
    levelId: editMode ? [initialMasterClass?.level?.[0]?.id || ""] : [""],
  });

  const [masterClassData, setMasterClassData] =
    useState<CreateMasterClassInput>(getInitialState());

  return [masterClassData, setMasterClassData] as const;
};

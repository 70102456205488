import { useQuery } from "@apollo/client";
import { FlexProps } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";

import { BadgeCardFragment } from "@/components/Badges/graphql/badgeCardFragment.generated";
import { CourseCard } from "@/components/Card/Course";
import { GetCourseProgressDocument } from "@/components/ProgressDashboard/graphql/getCourseProgress.generated";
import useGA4Event, {
  GA4EventAction,
  GA4EventCategory,
  GA4EventLabel,
} from "@/hooks/useGA4Event";
import { GetMyProgramCohortsDocument } from "@/pages/academy/Programs/graphql/getMyProgramCohorts.generated";
import { TestIds } from "@/utils/testIds";
import { ProgramCardFragment } from "./graphql/programCardFragment.generated";
import { Category } from "@/schemaTypes";

interface ProgramCardProps {
  isNewUi?: boolean;
  isList?: boolean;
  program: ProgramCardFragment;
  isNew?: boolean;
  isPro?: boolean;
  isSpecialization?: boolean;
  _badges?: Array<BadgeCardFragment>;
  onClick?: (program: ProgramCardFragment, e: React.MouseEvent) => void;
  onClickPro?: (program: ProgramCardFragment, e: React.MouseEvent) => void;
  onEditClick?: (program: ProgramCardFragment, e: React.MouseEvent) => void;
}

export const ProgramCard: React.FC<
  ProgramCardProps & Omit<FlexProps, "onClick">
> = ({
  isList,
  isNewUi,
  isNew,
  program,
  onClick,
  onEditClick,
  onClickPro,
  isSpecialization,
}) => {
  const { data: userCohortsData } = useQuery(GetMyProgramCohortsDocument, {
    variables: { programId: program.id ?? "", limit: 1 },
    fetchPolicy: "cache-first",
    context: {},
  });
  const [courseId, setCourseId] = useState("");
  const [cohortId, setCohortId] = useState("");
  const { sendEvent } = useGA4Event();

  useEffect(() => {
    if (userCohortsData?.me?.cohorts?.[0]?.id) {
      const firstCohort = userCohortsData.me.cohorts[0];
      const firstCohortId = firstCohort.id;
      const currentCourseId =
        firstCohort.userCurrentModule?.courseId ?? firstCohort.courseId[0];
      setCourseId(currentCourseId);
      setCohortId(firstCohortId);
    }
  }, [userCohortsData]);

  const { data: userCourseData } = useQuery(GetCourseProgressDocument, {
    variables: { courseId, cohortId },
    skip: !courseId || !cohortId,
    fetchPolicy: "no-cache",
  });

  let progress = userCourseData?.cohort?.userProgress?.progress ?? 0;

  progress = progress > 100 ? 100 : progress;

  const { imageUrl: badgeUrl, thumbnailUrl, name, status } = program;

  const handleClick = (e: React.MouseEvent) => {
    if (isSpecialization) {
      onClickPro?.(program, e);
    } else {
      onClick?.(program, e);
    }

    if (progress === 0) {
      sendEvent(
        {
          action: `${
            isSpecialization
              ? GA4EventAction.StartSpecialization
              : GA4EventAction.StartProgram
          }`,
          label: isSpecialization
            ? GA4EventLabel.StartSpecialization
            : GA4EventLabel.StartProgram,
          category: isSpecialization
            ? GA4EventCategory.Specializations
            : GA4EventCategory.Programs,
        },
        {
          [isSpecialization ? "especializacionName" : "diplomadoName"]:
            program.name,
        }
      );
    } else if (progress < 100) {
      sendEvent(
        {
          action: `${
            isSpecialization
              ? GA4EventAction.ContinueSpecialization
              : GA4EventAction.ContinueProgram
          }`,
          label: isSpecialization
            ? GA4EventLabel.ContinueSpecialization
            : GA4EventLabel.ContinueProgram,
          category: isSpecialization
            ? GA4EventCategory.Specializations
            : GA4EventCategory.Programs,
        },
        {
          [isSpecialization ? "especializacionName" : "diplomadoName"]:
            program.name,
        }
      );
    } else {
      sendEvent(
        {
          action: `${
            isSpecialization
              ? GA4EventAction.RepeatSpecialization
              : GA4EventAction.RepeatProgram
          }`,
          label: isSpecialization
            ? GA4EventLabel.RepeatSpecialization
            : GA4EventLabel.RepeatProgram,
          category: isSpecialization
            ? GA4EventCategory.Specializations
            : GA4EventCategory.Programs,
        },
        {
          [isSpecialization ? "especializacionName" : "diplomadoName"]:
            program.name,
        }
      );
    }
  };

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onEditClick) {
      onEditClick(program, e);
    }
  };

  return (
    <CourseCard
      border={"1px solid"}
      borderColor={isNewUi ? "transparent" : "neutral.100"}
      isList={isList ?? false}
      isNewUi={isNewUi ?? false}
      badge={{ src: isNewUi ? thumbnailUrl?.toString() : badgeUrl?.toString() }}
      data_cy={TestIds.DiplomatCardButton}
      course={{
        isNew,
        name,
        progress,
        status,
        type: program.programType ?? undefined,
        brand: (program.brand as Category[]) ?? [],
        subCategory: (program.subCategory as Category[]) ?? [],
        level: (program.level as Category[]) ?? [],
      }}
      onClick={handleClick}
      onEdit={handleEditClick}
    />
  );
};

import { useLazyQuery, useQuery } from "@apollo/client";
import { SearchProgramsDocument } from "../Programs/graphql/searchPrograms.generated";
import {
  EntityName,
  FilterByLiveSession,
  LiveSessionStatus,
  LiveSessionType,
} from "@/schemaTypes";
import {
  ListLiveSessionsForCurrentWeekDocument,
  ListLiveSessionsForCurrentWeekQuery,
} from "@/components/LiveSessions/graphql/ListLiveSessionsByWeek.generated";
import { useUser } from "@/providers/useUser";
import {
  GetMasterClassesDocument,
  GetMasterClassesQuery,
} from "../MasterClasses/graphql/getMasterClasses.generated";
import { LiveSessionCard } from "./types";
import DateTimeMoment from "@/utils/dateTimeFormat/dateTimeMoment";
import DateTimeInterface from "@/utils/dateTimeFormat/dateTimeInteface";
import moment from "moment";
import { CategoriesDocument } from "@/pages/Admin/Categories/graphql/getCategories.generated";
import { noCacheHeaders } from "@/utils/headers";
import { useContext, useEffect, useState } from "react";
import { GetLiveSessionsOnboardingDocument } from "@/pages/OnboardingSchedule/graphql/getLiveSessionsOnboarding.generated";
import { learnContext } from "../Learn/providers/learnProvider";

const parseLiveSessions = (
  userId?: string,
  listLiveSessionsByWeek?: ListLiveSessionsForCurrentWeekQuery,
  masterClassesList?: GetMasterClassesQuery
): Array<LiveSessionCard | undefined> => {
  const rawMasterClasses = masterClassesList?.getMasterClasses ?? [];
  const rawLiveSessions =
    listLiveSessionsByWeek?.getLiveSessionsForCurrentWeek ?? [];

  if (!rawLiveSessions?.length && !rawMasterClasses?.length) return [];

  const parsedLiveSessions = rawLiveSessions
    .map((session) => {
      if (!session?.sessions?.length) return;

      const sessions = session.sessions.map((liveClass) => {
        const isScheduled = liveClass?.attendees?.some(
          (attendee) => attendee.userId === userId
        );

        return {
          id: liveClass?.id ?? "",
          name: liveClass?.name ?? "",
          mentorName: liveClass?.mentorName ?? "",
          mentorAvatar: liveClass?.imageUrlMentor ?? "",
          startDate: liveClass?.startDate ?? new Date(),
          endDate: liveClass?.endDate,
          liveSessionType: liveClass?.liveSessionType,
          brand: liveClass?.brand,
          subCategory: liveClass?.subCategory,
          level: liveClass?.level,
          isCancelled: liveClass?.isCancelled,
          isLive: liveClass?.status === LiveSessionStatus.Live,
          isScheduled,
          attendees: liveClass?.attendees ?? [],
        };
      });
      return sessions;
    })
    .flat();

  const parsedMasterClasses = rawMasterClasses.map((masterClass) => {
    const parsedName = masterClass?.name.toLowerCase().startsWith("masterclass")
      ? masterClass?.name?.split(":").slice(1).join("")
      : masterClass?.name;

    return {
      id: masterClass?.id ?? "",
      name: parsedName ?? "",
      mentorName: `${masterClass?.mentorInfo?.name} ${masterClass?.mentorInfo?.lastName}`,
      mentorAvatar:
        (masterClass?.mentorInfo?.avatarUrl || masterClass?.imageUrlMentor) ??
        "",
      endDate: masterClass?.endDate,
      startDate: masterClass?.startDate ?? new Date(),
      liveSessionType: masterClass?.isWorkshop
        ? LiveSessionType.Workshop
        : LiveSessionType.Masterclass,
      brand: masterClass?.brand,
      subCategory: masterClass?.subCategory,
      level: masterClass?.level,
      isScheduled: masterClass?.ItIsScheduled,
      isCancelled: masterClass?.isCancelled,
      attendees: [],
    };
  });

  const sortedSessions = [...parsedLiveSessions, ...parsedMasterClasses].sort(
    (a, b) => {
      if (a && b) return a.startDate < b.startDate ? -1 : 1;
      return 0;
    }
  );

  const notEmptySessions = sortedSessions.filter(
    (session) =>
      session?.name &&
      session?.mentorName &&
      session?.brand &&
      session?.subCategory &&
      session?.level
  );

  return notEmptySessions as Array<LiveSessionCard>;
};

export const parseLiveSessionsDate = (
  date: Date,
  timezone: string,
  showYear: boolean = true
) => {
  const dateTime: DateTimeInterface = new DateTimeMoment();
  const format = dateTime.fortmatOnlyDateAndDay(moment(date), timezone, {
    day: "numeric",
    month: "long",
    year: showYear ? "numeric" : undefined,
  });
  const time = dateTime.fortmatOnlyTime(moment(date), timezone);
  return { format, time };
};

export const useQueryLiveSessions = (isOnboarding: boolean) => {
  const { geolocation, user } = useUser();
  const timezone = geolocation.location;
  const [newLiveSessions, setLiveSessions] = useState<LiveSessionCard[]>([]);

  const { data: programsData } = useQuery(SearchProgramsDocument, {
    variables: {
      programSearchInput: {
        entityName: EntityName.Diplomat,
      },
    },
  });

  const programsId = programsData?.searchPrograms?.map((program) => program.id);

  const [
    getLiveSessionsByWeek,
    { data: listLiveSessionsByWeek, loading: sessionsByWeekLoading },
  ] = useLazyQuery(ListLiveSessionsForCurrentWeekDocument, {
    variables: {
      liveSessionsSearchInput: {
        programsId,
        modulesId: [],
        timezone: timezone,
        filterBy: FilterByLiveSession.Programs,
      },
    },
    fetchPolicy: "no-cache",
    context: { headers: noCacheHeaders },
  });

  const [
    getMasterClasses,
    { data: masterClassesList, loading: masterclassesLoading },
  ] = useLazyQuery(GetMasterClassesDocument, {
    fetchPolicy: "no-cache",
    context: { headers: noCacheHeaders },
  });
  const [
    fetchLiveSessionsOnboarding,
    { data: liveSessionsOnboarding, loading: liveSessionsLoading },
  ] = useLazyQuery(GetLiveSessionsOnboardingDocument, {
    fetchPolicy: "no-cache",
    variables: {
      timezone,
      createdAt: user?.createdAt?.toString(),
    },
    context: { headers: noCacheHeaders },
  });

  const fetchLivesessions = () => {
    getLiveSessionsByWeek();
    getMasterClasses();
    if (isOnboarding) {
      fetchLiveSessionsOnboarding();
    }
  };
  useEffect(() => {
    fetchLivesessions();
  }, [isOnboarding]);

  useEffect(() => {
    if (liveSessionsOnboarding?.getLiveSessionsOnboarding) {
      const liveSessionsData =
        liveSessionsOnboarding?.getLiveSessionsOnboarding.map((session) => {
          const isScheduled = session?.attendees?.some(
            (attendee) => attendee.userId === user?.id
          );

          const objLiveSession = {
            id: session?.id ?? "",
            name: session?.name ?? "",
            mentorName: session?.mentorName ?? "",
            mentorAvatar: session?.imageUrlMentor ?? "",
            mainMentors: session?.mainMentors,
            startDate: session?.startDate ?? new Date(),
            endDate: session?.endDate,
            liveSessionType: session?.liveSessionType,
            brand: session?.brand,
            subCategory: session?.subCategory,
            level: session?.level,
            isCancelled: session?.isCancelled,
            isLive: session?.status === LiveSessionStatus.Live,
            isScheduled,
          };
          return objLiveSession;
        });
      setLiveSessions(liveSessionsData as Array<LiveSessionCard>);
    }
  }, [liveSessionsOnboarding]);

  const liveSessions = isOnboarding
    ? newLiveSessions
    : parseLiveSessions(
        user?.id,
        listLiveSessionsByWeek,
        masterClassesList
      ).filter(
        (session) =>
          session?.liveSessionType !== LiveSessionType.OnboardingSession
      );

  const schedulledLiveSessions = liveSessions.filter(
    (session) => session?.isScheduled
  );

  return {
    liveSessions,
    schedulledLiveSessions,
    refetchLiveSessions: fetchLivesessions,
    liveSessionsLoading:
      sessionsByWeekLoading || masterclassesLoading || liveSessionsLoading,
  };
};

export const useLiveSessionStatus = (session: LiveSessionCard) => {
  const { liveSessions } = useContext(learnContext);
  const { timezone } = useUser();
  const dateTime: DateTimeInterface = new DateTimeMoment();

  const isSessionScheduled =
    session?.isScheduled ||
    !!liveSessions?.scheduled.find(
      (liveSession) => liveSession.id === session.id
    );

  const localStartDate = dateTime.convertLocalTime(
    moment(session?.startDate),
    timezone
  );

  const localEndDate = dateTime.convertLocalTime(
    moment(session?.endDate),
    timezone
  );

  const now = moment();

  const isGeneral =
    session.brand?.[0]?.name === "Sin marca" &&
    session.subCategory?.[0]?.name === "Sin subcategoría" &&
    session.level?.[0]?.name === "Sin nivel";

  const isSessionLive =
    now.isSameOrAfter(localStartDate.subtract(5, "minutes")) &&
    now.isBefore(localEndDate) &&
    !session?.isCancelled;
  const showEnterOption = isSessionScheduled && isSessionLive;
  const showRegisterOption = !isSessionScheduled && !session.isCancelled;
  const showCancelOption =
    isSessionScheduled && !session.isCancelled && !isSessionLive;

  return {
    showEnterOption,
    showRegisterOption,
    showCancelOption,
    isGeneral,
  };
};

export const useCategoriesOptions = () => {
  const { data: categories } = useQuery(CategoriesDocument, {
    fetchPolicy: "no-cache",
    context: { headers: noCacheHeaders },
  });

  const brandCategories = categories?.categories.filter(
    (category) => category.type.toLowerCase() === "principal"
  );

  const subCategories = categories?.categories.filter(
    (category) => category.type.toLowerCase() === "subcategoria"
  );

  const levelCategories = categories?.categories.filter(
    (category) => category.type.toLowerCase() === "nivel"
  );

  return {
    brandCategories,
    subCategories,
    levelCategories,
  };
};

export const parseLiveSessionType = (type: LiveSessionType) =>
  type.charAt(0).toUpperCase() + type.slice(1).toLowerCase().replace(/_/g, " ");

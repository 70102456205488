import { useContext } from "react";
import { learnContext } from "../providers/learnProvider";
import { BootcampBannerEntry, Program } from "@/schemaTypes";

export const useUniqueCategories = () => {
  const { brandData } = useContext(learnContext);

  if (!brandData) return [];

  const { bootcamps, liveSessions, programs } = brandData;

  const bootcampsSubCategories = [
    ...(bootcamps?.cta ?? []),
    ...(bootcamps?.myBootcamps ?? []),
    ...(bootcamps?.completed ?? []),
  ].flatMap(
    (bootcamp: BootcampBannerEntry) =>
      bootcamp?.parent?.subCategory?.map((category) => category?.name) ?? []
  );

  const programsSubCategories =
    programs?.flatMap((program: Program) =>
      program.subCategory?.map((category) => category?.name)
    ) ?? [];

  const liveSessionsSubCategories = [
    ...(liveSessions?.next ?? []),
    ...(liveSessions?.scheduled ?? []),
  ].flatMap(
    (session) => session?.subCategory?.map((category) => category?.name) ?? []
  );

  const uniqueCategories = Array.from(
    new Set([
      ...bootcampsSubCategories,
      ...programsSubCategories,
      ...liveSessionsSubCategories,
    ])
  ).filter((category) => category !== undefined);

  return uniqueCategories;
};
